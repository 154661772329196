import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import MmlpAbout from "../../../images/other/mmlpabout.jpg";
import CFS from "../../../images/other/cfs.jpg";
import IDC from "../../../images/other/idc.jpg";
import Planning from "../../../images/mmlpicon/planning.svg";
import Instruction from "../../../images/mmlpicon/instruction.svg";
import Delivery from "../../../images/mmlpicon/delivery.svg";
import Unloading from "../../../images/mmlpicon/unloading.svg";
import Transportation from "../../../images/mmlpicon/transportation.svg";
import Clearance from "../../../images/mmlpicon/clearance.svg";
import Documentation from "../../../images/mmlpicon/documentation.svg";
import International from "../../../images/mmlpicon/international.svg";
import Port from "../../../images/mmlpicon/port.svg";
import ContainerTruck from "../../../images/mmlpicon/container-truck.svg";
const Company = () => {
  return (
    <div className="container py-3 py-lg-4">
      <div className="row g-0 mb-3 ">
        <div className="col border-bottom border-1 border-dark">
          <h1>Logistics Park</h1>
        </div>
      </div>

      <div className="row ">
        <div className="col-12 col-md-6 mb-3 mb-md-0 ">
          <div
            className="card-body h-100 gray-bg d-flex flex-column"
            style={{ padding: "16px" }}
          >
            <h2 className="card-title" style={{ marginBottom: "8px" }}>
              About us
            </h2>
            <p className="card-text">
              At Delta Corp Logistics, we are committed to transforming India's
              logistics landscape through the development of Multi-Modal
              Logistics Parks (MMLPs).
            </p>
            <p>
              Our initiative is aligned with the Government of India's policy to
              enhance the freight logistics sector, ensuring efficient and
              cost-effective movement of goods across the country.
            </p>

            <div className="text-end border-top border-2 border-dark pt-3 mt-auto"></div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <img  className="img-responsive" src={MmlpAbout} width={640} />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <div className="padding-top row g-0 mb-3 ">
            <div className="col border-bottom border-1 border-dark">
              <h1>Main Modes of transport</h1>
            </div>
          </div>

          <div className="row ">
            <div className="col-12 col-md-6 mb-3 mb-md-0 ">
              <div
                className="card-body h-100 gray-bg d-flex flex-column"
                style={{ padding: "16px" }}
              >
                <h2 className="card-title" style={{ marginBottom: "8px" }}>
                  CFS - Container Freight Station
                </h2>
                <p className="card-text">
                  At Delta Corp, our Container Freight Station (CFS) is a
                  critical part of our logistics and supply chain services,
                  strategically designed to streamline the handling and storage
                  of goods.
                </p>
                <p>
                  Acting as an intermediary between sea transport and inland
                  logistics, our CFS ensures that cargo is efficiently
                  consolidated and deconsolidated, ready for smooth transfer
                  across different modes of transport.
                </p>
                <p>
                  At Delta Corp, we take pride in being a trusted partner for
                  managing complex freight logistics, ensuring that goods move
                  swiftly from ports to their final destinations.
                </p>

                <div className="text-end border-top border-2 border-dark pt-3 mt-auto"></div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img className="img-responsive" src={CFS} width={640} />
            </div>
          </div>
        </div>
      </div>
      <div className="row padding-top">
        <div className="col-12 col-md-6 ">
          <img className="img-responsive" src={IDC} width={640} />
        </div>
        <div className="col-12 col-md-6 mb-3 mb-md-0 ">
          <div
            className="card-body h-100 gray-bg d-flex flex-column"
            style={{ padding: "16px" }}
          >
            <h2 className="card-title" style={{ marginBottom: "8px" }}>
              ICD - Inland Container Depot
            </h2>
            <p className="card-text">
              Delta Corp's Inland Container Depot (ICD) is a vital part of our
              logistics network, designed to handle and manage the flow of
              containerized cargo from ports to inland destinations.
            </p>
            <p>
              Our ICDs function as key logistics hubs, providing a range of
              services that enhance the efficiency of international trade and
              the smooth movement of goods across borders.
            </p>
            <p>
              Our ICD is a critical part of Delta Corp’s commitment to providing
              end-to-end logistics solutions, enabling better management of
              goods, faster transportation, and streamlined processes for our
              clients, both locally and internationally.
            </p>
            <div className="text-end border-top border-2 border-dark pt-3 mt-auto"></div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-top">
          <div
            className="card-body h-100 gray-bg d-flex flex-column"
            style={{ padding: "16px" }}
          >
            <div className="row mb-5 ">
              <div className="col">
                <h2 className="card-title">ICD/CFS- Import</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 text-center">
                <img src={Planning} width={60} />
                <div className="fw-bold mb-3">Pre-shipment planning</div>
                <p className="text-start">
                  Organizing logistics and preparing necessary documents before
                  shipment.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={Instruction} width={60} />
                <div className="fw-bold mb-3">Shipping instructions</div>
                <p className="text-start">
                  Sending detailed instructions for cargo handling and
                  transport.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={Clearance} width={60} />
                <div className="fw-bold mb-3">
                  Customs clearance at the port
                </div>
                <p className="text-start">
                  Goods are inspected and cleared by customs authorities upon
                  arrival.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={Transportation} width={60} />
                <div className="fw-bold mb-3">Transport to ICD/CFS</div>
                <p className="text-start">
                  Cargo is transported from the port to the ICD or CFS for
                  further handling.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={Unloading} width={60} />
                <div className="fw-bold mb-3">Unloading and storage</div>
                <p className="text-start">
                  Goods are unloaded and stored at the ICD/CFS before final
                  dispatch.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={Delivery} width={60} />
                <div className="fw-bold mb-3">
                  Distribution to final destination
                </div>
                <p className="text-start">
                  Cleared goods are sent to the final destination via road or
                  rail.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 padding-top">
          <div
            className="card-body h-100 gray-bg d-flex flex-column"
            style={{ padding: "16px" }}
          >
            <div className="row mb-5 ">
              <div className="col">
                <h2 className="card-title">ICD/CFS- Export</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 text-center">
                <img src={Documentation} width={60} />
                <div className="fw-bold mb-3">Pre-export documentation</div>
                <p className="text-start">
                  Preparing export-related documents like bill of lading and
                  shipping instructions.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={ContainerTruck} width={60} />
                <div className="fw-bold mb-3">
                  Container loading and transport to ICD/CFS
                </div>
                <p className="text-start">
                  Containers are loaded and sent to the ICD or CFS for export
                  processing.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={Clearance} width={60} />
                <div className="fw-bold mb-3">Customs clearance for export</div>
                <p className="text-start">
                  Goods are inspected and cleared for export at ICD/CFS.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={Port} width={60} />
                <div className="fw-bold mb-3">Sea/road transport to port</div>
                <p className="text-start">
                  Export cargo is transported to the port via sea or road for
                  international shipment.
                </p>
              </div>
              <div className="col-12 col-md-4 text-center">
                <img src={International} width={60} />
                <div className="fw-bold mb-3">
                  Shipping to international destination
                </div>
                <p className="text-start">
                  Cleared goods are shipped to the international destination.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-3 py-lg-4 ">
        <div className="row g-0 mb-3 padding-top">
          <div className="col border-bottom border-1 border-dark">
            <h1>IOT in Logistics</h1>
          </div>
        </div>
        <div className="row">
          <div className="card mb-3 border-0">
            <div className="row gx-3">
              <div className="col-lg-6">
                <div className="card-body gray-bg d-flex flex-column">
                  <p className="card-text">
                    The Internet of Things (IoT) revolutionizes logistics by
                    enabling real-time tracking and monitoring of shipments and
                    assets, enhancing supply chain visibility. With IoT sensors,
                    companies can optimize routes, manage inventory efficiently,
                    and ensure the integrity of sensitive goods during transit.
                    This technology significantly reduces operational costs,
                    improves decision-making, and enhances overall customer
                    satisfaction.
                  </p>
                  <div className="mb-3">
                    <h4>RFID (Radio Frequency Identification) Systems</h4>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item gray-bg">
                        RFID systems track goods in CFS and ICD environments by
                        attaching tags to items, enabling real-time scanning and
                        inventory management as they move through the logistics
                        chain. This technology enhances visibility, streamlines
                        processes, and improves overall efficiency by automating
                        inventory checks and reducing manual errors.
                      </li>
                      <li className="list-group-item gray-bg">
                        Each time an item is moved, its RFID tag can be scanned,
                        updating the warehouse management system (WMS) in
                        real-time.
                      </li>
                      <li className="list-group-item gray-bg">
                        Reduced manual errors, quicker tracking of goods, and
                        enhanced visibility.
                      </li>
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h4>Weighbridge Integration</h4>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item gray-bg">
                        Weighbridge systems linked with IoT sensors can
                        automatically record the weight of containers at both
                        entry and exit points (CFS/ICD).
                      </li>
                      <li className="list-group-item gray-bg">
                        Real-time weight monitoring, reducing the chances of
                        discrepancies, optimizing load management, and faster
                        processing times.
                      </li>
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h4>Location Tracking in Warehouse</h4>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item gray-bg">
                        Use IoT sensors and GPS to track the location of
                        containers and goods inside the warehouse
                      </li>
                      <li className="list-group-item gray-bg">
                        Enhanced accuracy in inventory management, quick access
                        to specific goods, and better space utilization.
                      </li>
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h4>Smart Warehouse Solutions</h4>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item gray-bg">
                        Use IoT-enabled conveyors to automatically sort goods
                        based on destination, type, or container.
                      </li>
                      <li className="list-group-item gray-bg">
                        Especially for multimedia assets, where the condition is
                        crucial (e.g., avoiding damage from heat or humidity).
                      </li>
                      <li className="list-group-item gray-bg">
                        IoT sensors can detect wear and tear in handling
                        equipment, minimizing downtime and repair costs.
                      </li>
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h4>IoT Data Analytics</h4>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item gray-bg">
                        Presents a unified interface where all IoT data (RFID
                        readings, weighbridge data, location tracking, road, and
                        rail transport) can be monitored.
                      </li>
                      <li className="list-group-item gray-bg">
                        Use data collected from IoT devices to predict delays,
                        optimize load distribution, and reduce operational
                        inefficiencies.
                      </li>
                      <li className="list-group-item gray-bg">
                        Automated reports on the performance of containers,
                        trucks, and trains in terms of time, cost, and resource
                        utilization.
                      </li>
                    </ul>
                  </div>
                  <div className="mb-3">
                    <h4>Benefits of IoT for Clients</h4>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item gray-bg">
                        Faster processing at CFS and ICD, optimized
                        transportation.
                      </li>
                      <li className="list-group-item gray-bg">
                        Reduced manual errors, lower fuel costs, and optimized
                        inventory management.
                      </li>
                      <li className="list-group-item gray-bg">
                        End-to-end visibility of shipments from warehouse to
                        final destination.
                      </li>
                      <li className="list-group-item gray-bg">
                        Tracking not just location but also conditions like
                        temperature and vibration for sensitive media equipment.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <StaticImage
                  className="mb-3"
                  transformOptions={{ cropFocus: "attention" }}
                  alt="Sales team"
                  src="../../../images/other/iot.jpg"
                  aspectRatio={4 / 3}
                  width={696}
                />
                <div className="col-12">
                  <div className="card-body gray-bg d-flex flex-column mb-3">
                    <div className="mb-3">
                      <h4>IoT in Rail Transport</h4>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item gray-bg">
                          Sensors on rail containers to monitor realtime
                          location, temperature, and security.
                        </li>
                        <li className="list-group-item gray-bg">
                          This information can be fed into the logistics park’s
                          central system, ensuring visibility from warehouse to
                          destination.
                        </li>
                        <li className="list-group-item gray-bg">
                          Timely delivery updates, better route planning, and
                          predictive maintenance for rail containers.
                        </li>
                        <li className="list-group-item gray-bg">
                          Intregration with Frieght operatuions information
                          system.
                        </li>
                      </ul>
                    </div>
                    <div className="mb-3">
                      <h4>IoT in Road Transport</h4>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item gray-bg">
                          Utilize GPS and IoT in trucks for realtime tracking,
                          monitoring fuel usage, and driver performance.
                        </li>
                        <li className="list-group-item gray-bg">
                          Ensure seamless transfer of data from the warehouse
                          weighbridge to trucks for quick dispatch.
                        </li>
                        <li className="list-group-item gray-bg">
                          Optimized rout e planning, reduced fuel costs, and
                          enhanced shipment security.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <StaticImage
                    className="mb-3"
                    transformOptions={{ cropFocus: "attention" }}
                    alt="Sales team"
                    src="../../../images/other/roadtransport.jpg"
                    aspectRatio={4 / 3}
                    width={696}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
